<template>
	<div>
        <v-row v-if="!loading && !permissions.can_list">
            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <p class="text-h5 mb-4">Project Resource Management</p>
                <p class="text-title mb-0">You do not have permission to view all project resources</p>
            </v-col>
        </v-row>
        <template v-if="permissions.can_list">
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-sheet color="white" elevation="0" class="pa-0">
                        <p class="text-h6 mb-4">Search</p>
                        <v-row>
                            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" v-if="projects.length > 0">
                                <v-autocomplete dense clearable hide-details label="Project" :items="projects" v-model="filters.project"></v-autocomplete>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <v-text-field dense clearable hide-details label="File Name" v-model="filters.name"></v-text-field>
                            </v-col>
                            <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" order="last">
                                <v-btn small color="primary" class="mr-2 elevation-0" :ripple="false" @click="search">
                                    <v-icon small left>mdi-magnify</v-icon> Search
                                </v-btn>
                                <v-btn small class="elevation-0" :ripple="false" @click="reset">
                                    <v-icon small left>mdi-undo</v-icon> Reset
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-sheet>
                </v-col>
            </v-row>
            <v-row v-if="permissions.can_add">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-btn small color="primary" class="elevation-0" :ripple="false" @click='openCreateDialog'>
                        <v-icon small left>mdi-plus</v-icon> Upload Resource
                    </v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-data-table hide-default-footer class="elevation-0" loading-text="Loading... Please wait..." no-data-text="No data available yet" :loading="loading" :items-per-page="50" :headers="headers" :items="items">
                        <template v-slot:item='{ item, index }'>
                            <tr>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="index + 1"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.project"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.name"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.file_type"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.uploader"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.expiry_days"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.created_on"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.updated_on"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.left_days"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center" v-text="item.delete_on"></td>
                                <td class="text-center text-xs-center text-sm-center text-md-center text-lg-center text-xl-center">
                                    <v-menu bottom offset-y v-if="hasMenu(item)">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn small icon v-bind="attrs" v-on="on">
                                                <v-icon>mdi-dots-horizontal</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list dense>
                                            <v-list-item @click="openNewTab(item.file_url)" target="_blank" v-if="item.file_url">
                                                <v-list-item-title>View Resource</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="openUpdateDialog(item, index)" v-if="item.can_edit">
                                                <v-list-item-title>Update Resource</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item @click="openDeleteDialog(item)" v-if="item.can_delete">
                                                <v-list-item-title>Delete Resource</v-list-item-title>
                                            </v-list-item>
                                        </v-list>
                                    </v-menu>
                                </td>
                            </tr>
                        </template>
                    </v-data-table>
                </v-col>
            </v-row>
            <v-row v-if="!loading && pagination.total_pages > 1">
                <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <v-pagination circle :length="pagination.total_pages" :total-visible="7" v-model="filters.page" @input="get"></v-pagination>
                </v-col>
            </v-row>
        </template>
		<create-dialog :projects="projects" ref="createDialog" @created="updateItems"></create-dialog>
		<update-dialog ref="updateDialog" @updated="updateItem"></update-dialog>
		<delete-dialog ref="deleteDialog" @deleted="updateItems"></delete-dialog>
		<message-notifier ref="notifier"></message-notifier>
	</div>
</template>


<script>

import {openNewTab} from '@/utils/helper'
import MessageNotifier from '../../../shared/MessageNotifier.vue'
import ProjectResourceCreateDialog from './ProjectResourceCreateDialog.vue'
import ProjectResourceUpdateDialog from './ProjectResourceUpdateDialog.vue'
import ProjectResourceDeleteDialog from './ProjectResourceDeleteDialog.vue'

export default {
	name: 'ProjectResourceList',
	components: {
        messageNotifier: MessageNotifier,
		createDialog: ProjectResourceCreateDialog,
		updateDialog: ProjectResourceUpdateDialog,
		deleteDialog: ProjectResourceDeleteDialog,
	},
	data () {
		return {
			loading: false,
			selectedIndex: -1,
			filters: {
				project: '',
				name: '',
				source: 'project',
				page: 1
			},
            permissions: {
                can_list: false,
                can_add: false
            },
			projects: [],
			items: [],
			headers: [],
			pagination: {}
		}
	},
	computed: {
		createDialog() {
			return this.$refs.createDialog
		},
		updateDialog() {
			return this.$refs.updateDialog
		},
        deleteDialog() {
            return this.$refs.deleteDialog
        },
		notifier() {
            return this.$refs.notifier
        }
	},
	mounted: function() {
		this.get()
	},
	methods: {
		get: function() {
			this.loading = true
			this.items = []
			this.$store.dispatch('epanel/project/getProjectResources', this.filters).then((response) => {
				this.projects = response.data.projects
				this.items = response.data.items
				this.headers = response.data.headers
				this.pagination = response.data.pagination
				this.permissions = response.data.permissions
				this.loading = false
			}).catch(() => {
				this.loading = false
			})
		},
		search: function() {
			this.loading = true
			this.filters.page = 1
			this.get()
		},
		reset: function() {
			this.filters = { project: '', name: '', source: 'project', page: 1}
		},
		openCreateDialog: function() {
            if(this.permissions.can_add){
                this.createDialog.open()
            }
		},
        openUpdateDialog: function(item, index) {
            if(item.can_edit){
                this.selectedIndex = index
                this.updateDialog.updateObject({ id: item.id, project: item.project, name: item.name, expiry_days: item.expiry_days })
                this.updateDialog.open()
            }
        },
        openDeleteDialog: function(item) {
            if(item.can_delete){
                this.deleteDialog.updateObject({id: item.id})
                this.deleteDialog.open()
            }
        },
        openNewTab: function(url) {
            openNewTab(url)
        },
		openNotifier: function(message) {
            this.notifier.updateText(message)
            this.notifier.open()
        },
        updateItems: function(item) {
            this.reset()
            this.get()
            this.openNotifier(item.message)
		},
		updateItem: function(item) {
            this.$set(this.items, this.selectedIndex, item.object)
            this.openNotifier(item.message)
		},
        hasMenu: function(item) {
            return item.can_edit || item.can_delete || item.file_url
        }
	}
}

</script>